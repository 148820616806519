const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://learn.spathesystems.com',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://spathesystems.com/wp-content/uploads/2016/11/ss-logo-white-1.png',
    logoLink: 'https://learn.spathesystems.com',
    title: '<h2 style="margin-bottom: 15px;">Learn</h2>',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: '',
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'prod_learn.spathesystems.com',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/catalog/1-catalog',
      '/environment/1-environment',
      '/devsecops',
      '/agilescrum',
      '/foundations',
      '/postgresql',
      '/apis',
      '/front-end',
      '/deploying',
      '/cheatsheets',
      '/answerkeys'
    ],
    collapsedNav: [
      '/catalog/1-catalog',
      '/environment/1-environment',
      '/devsecops',
      '/agilescrum',
      '/foundations',
      '/postgresql',
      '/apis',
      '/front-end',
      '/deploying',
      '/cheatsheets',
      '/cheatsheets/1-foundations',
      '/answerkeys'
    ],
    links: [
    ],
    frontline: false,
    ignoreIndex: true,
    title: "<p>MTT</p><div class='greenCircle'></div><p>DevSecOps</p>",
  },
  siteMetadata: {
    title: 'Spathe Systems Learning',
    description: 'DevSecOps course provided by the Spathe Systems MTT Team',
    ogImage: null,
    docsLocation: 'https://gitlab.com/spathelab/learn.spathesystems.com/-/tree/master/content',
    favicon: 'https://learn.spathesystems.com/spathe_logo_light.png',
  },
  pwa: {
    enabled: true, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Spathe Systems Learning',
      short_name: 'learn.spathesystems.com',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
